/* * {
  box-sizing: border-box;
  user-select: none;
} */

.container {
    margin: 30px;
}



.info {
    text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
}

.nav-header .logo {
    width: 25%;
}

.nav-header nav {
    width: 75%;
}


.nav-header {

    border-top: 1px solid rgba(128, 128, 128, 0.496);
    border-bottom: 1px solid rgba(128, 128, 128, 0.449);
}

.logo {
    text-align: center;
    border-right: 1px solid #4d4d4d;
    padding: 12px 24px 13px;
}

.logo img {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
}

.item {
    padding: 12px 16px 13px;
}

.item:not(:last-child) {
    border-right: 1px solid #7e7e7e;
}

header,
nav {
    font-size: 0;
    color: black;
}

.item {
    font-size: 24px;
}

.logo,
.item,
.middle {
    display: inline-block;
    vertical-align: middle;
}

.scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.scroll::-webkit-scrollbar {
    display: none;
}



@media (max-width: 765px) {
    .item {
        font-size: 14px;
    }
    .info img { 
        width: 30rem;
    }
}
* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 50%;
}

@media only screen and (min-width: 960px) {
  html {
      font-size: 62.5%;
  }
}

body {
  font-weight: 400;
  line-height: 1.6;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}


/*
code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
} */


/* Mobile Styles */


/* Small Tablets Styles */


/* @media screen and (min-width: 620px){

} */


/* large tablet & laptop styles */


/* @media screen and (min-width: 960px){
body{
  font-size: 18px;
}
} */


/* desktop styles */


/* @media screen and (min-width: 1200px){
body{
  font-size: 20px;
}
} */


/* default styles here for older browsers. 
     I tend to go for a 600px - 960px width max but using percentages
  */

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
}

@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
}

@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}

@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
}

@media only screen and (device-width: 768px) {
  /* default iPad screens */
}


/* different techniques for iPad screening */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  /* For portrait layouts only */
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
  /* For landscape layouts only */
}